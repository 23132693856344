import 'prismjs/themes/prism-dark.css';
import type { Component } from 'solid-js';
import { marked } from 'marked';
import Prism from 'prismjs';
import { createSignal, createEffect } from 'solid-js';

const markedOptions: marked.MarkedOptions = {
  highlight: function (code: string, lang: string) {
    return lang ? Prism.highlight(code, Prism.languages[lang], lang) : code;
  },
};

interface Props {
  content: string;
  class: string;
  isEditable?: boolean;
  onInput?: (content: string) => void;
  inputProps?: Record<string, string>;
}

export const Markdown: Component<Props> = ({
  isEditable = false,
  content,
  onInput = () => {},
  inputProps = {},
  ...props
}) => {
  const [value, setValue] = createSignal(content);
  createEffect(() => onInput(value()));

  return (
    <div class={`flex gap-4 ${props.class}`}>
      {isEditable && (
        <div class="flex flex-1 flex-col overflow-hidden">
          <textarea
            {...inputProps}
            class="resize-y textarea textarea-bordered text-white h-full"
            value={value()}
            oninput={(value) => setValue(value.currentTarget.value)}
          />
        </div>
      )}
      <div
        class={`flex-1 markdown-body overflow-x-auto w-full overflow-y-hidden inline-block space-y-4`}
        innerHTML={marked(value(), markedOptions)}
      />
    </div>
  );
};
